import { FC, useEffect, useState, useRef, ChangeEvent } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { DropdownOption } from "components/ui/optionDropdown/optionDropdown";
import { Button, ButtonStyle } from 'components/ui/button/button';
import { Field } from "components/ui/field/field";
import cn from "clsx";
import styles from "./referenceDocumentForm.module.scss";
import { DocProject, DocRef, DocRefType, DocRefStatus, OrgAuthor, StudyAreaBoundary, Language } from "actions/lib/types";
import { useAdminState } from 'context/admin';
import { updateDocRef, createDocRef, fetchGeocodingResults, fetchAddressDetails } from 'actions/adminActions';
import { debounce } from 'lodash';
import StudyAreaBoundaryComponent from '../studyAreaBoundary/studyAreaBoundary';

interface Location {
  lon: string;
  lat: string;
}

export interface ReferenceDocumentFormFields {
  type_id?: number;
  project_id?: number;
  link_doc: boolean;
  title?: string;
  prepared_by_id?: number;
  prepared_for_id?: number;
  date_issued?: string;
  status?: string;
  url?: string;
  pdf_link?: string;
  language_id?: number;
  boundary_id?: number;
  boundary_page_ref?: number;
  description?: string;
  tags?: string;
  municipality?: string;
  region?: string;
  state?: string;
  country?: string;
}

const CANADA_LOCATION_SUFFIX = 'Canada';

interface ReferenceDocumentFormProps extends RouteComponentProps {
  selectedDocRef: DocRef;
  onCancel: () => void;
  onSubmitSuccess?: () => void;
}

interface BoundaryOption {
  value: number;
  label: string;
  geom: string;
  extent: number[] | undefined;
  thumbnail: string | undefined | null;
}

export const ReferenceDocumentFormComponent: FC<ReferenceDocumentFormProps> = ({ selectedDocRef, onCancel, onSubmitSuccess }) => {

  const adminState = useAdminState();

  const {
    docProjectList,
    docRefTypeList,
    orgAuthorList,
    studyAreaBoundaryList,
    languageList,
    isLoading,
  } = adminState;

  const [error, setError] = useState<string | null>(null);

  const { register, handleSubmit, formState: { errors }, watch, setValue } = useForm<ReferenceDocumentFormFields>();

  const docProjects = docProjectList.map((docProject: DocProject) => ({ value: docProject.id, label: docProject.title }));
  const docTypes = docRefTypeList.map((docRefType: DocRefType) => ({ value: docRefType.id, label: docRefType.name }));
  const docRefStatuses = Object.values(DocRefStatus).map(status => ({
    value: status,
    label: status
  }));
  const organizations = orgAuthorList.map((orgAuthor: OrgAuthor) => ({ value: orgAuthor.id, label: orgAuthor.name }));
  const boundaries = studyAreaBoundaryList.map((studyAreaBoundary: StudyAreaBoundary) => ({
    value: studyAreaBoundary.gid,
    label: studyAreaBoundary.name,
    geom: studyAreaBoundary.geom,
    extent: studyAreaBoundary.extent,
    thumbnail: studyAreaBoundary.thumbnail
  }));
  const languages = languageList.map((language: Language) => ({ value: language.id, label: language.name }));

  const [selectedProject, setSelectedProject] = useState<DropdownOption | undefined>();
  const [selectedDocType, setSelectedDocType] = useState<DropdownOption | undefined>();
  const [selectedPreparedBy, setSelectedPreparedBy] = useState<DropdownOption | undefined>();
  const [selectedPreparedFor, setSelectedPreparedFor] = useState<DropdownOption | undefined>();
  const [selectedBoundary, setSelectedBoundary] = useState<DropdownOption | undefined>();
  const [selectedStatus, setSelectedStatus] = useState<DropdownOption | undefined>(
    docRefStatuses.find(s => s.value === selectedDocRef?.status)
  );
  const [selectedLanguage, setSelectedLanguage] = useState<DropdownOption | undefined>();

  const [isLinkDoc, setIsLinkDoc] = useState(selectedDocRef?.link_doc || false);

  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [showAutocomplete, setShowAutocomplete] = useState(false);
  const [activeField, setActiveField] = useState<string>('');

  const debouncedSearch = useRef(
    debounce(async (searchTerm: string, options: string, layer?: string) => {
      if (searchTerm.length >= 3) {
        const results = await fetchGeocodingResults(searchTerm, options, layer);
        setSearchResults(results?.features || []);
        setShowAutocomplete(true);
      } else {
        setSearchResults([]);
        setShowAutocomplete(false);
      }
    }, 300)
  ).current;

  const handleLocationFieldChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (event.target instanceof HTMLInputElement) {
      const { name, value } = event.target;
      setValue(name as keyof ReferenceDocumentFormFields, value);
      setActiveField(name);
      
      // Pass appropriate layer parameter based on field name
      if (name === 'country') {
        debouncedSearch(value, CANADA_LOCATION_SUFFIX, 'country');
      } else if (name === 'state') {
        debouncedSearch(value, CANADA_LOCATION_SUFFIX, 'state');
      } else if (name === 'region') {
        debouncedSearch(value, CANADA_LOCATION_SUFFIX, 'district');
      } else if (name === 'municipality') {
        debouncedSearch(value, CANADA_LOCATION_SUFFIX, 'county');
      } else {
        debouncedSearch(value, CANADA_LOCATION_SUFFIX);
      }
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && showAutocomplete) {
      event.preventDefault();
      setShowAutocomplete(false);
    } else if (event.key === 'Escape' && showAutocomplete) {
      event.preventDefault();
      setShowAutocomplete(false);
    }
  };

  const handleAutocompleteSelect = async (feature: any) => {
    const properties = feature.properties;

    try {
      // First set the immediate value for the active field
      switch (activeField) {
        case 'municipality':
          setValue('municipality', properties.name);
          
          // For municipality selection, fetch additional details to fill other fields
          if (properties.osm_type && properties.osm_id) {
            const osmIdWithType = `${properties.osm_type.charAt(0).toUpperCase()}${properties.osm_id}`;
            const addressDetails = await fetchAddressDetails(osmIdWithType);
            
            if (addressDetails && addressDetails[0]) {
              const details = addressDetails[0].address;
              
              // Fill in the location fields if they exist
              if (details.district) {
                setValue('region', details.district);
              }
              if (details.state) {
                setValue('state', details.state);
              }
              if (details.country) {
                setValue('country', details.country);
              }
            }
          }
          break;
        case 'region':
          setValue('region', properties.name);

          // For region selection, fetch additional details to fill other fields
          if (properties.osm_type && properties.osm_id) {
            const osmIdWithType = `${properties.osm_type.charAt(0).toUpperCase()}${properties.osm_id}`;
            const addressDetails = await fetchAddressDetails(osmIdWithType);
            if (addressDetails && addressDetails[0]) {
              const details = addressDetails[0].address;
              if (details.state) {
                setValue('state', details.state);
              }
              if (details.country) {
                setValue('country', details.country);
              }
            }
          }
          break;
        case 'state':
          setValue('state', properties.name);

          // For state selection, fetch additional details to fill other fields
          if (properties.osm_type && properties.osm_id) {
            const osmIdWithType = `${properties.osm_type.charAt(0).toUpperCase()}${properties.osm_id}`;
            const addressDetails = await fetchAddressDetails(osmIdWithType);
            if (addressDetails && addressDetails[0]) {
              const details = addressDetails[0].address;
              if (details.country) {
                setValue('country', details.country);
              }
            }
          }
          break;
        case 'country':
          setValue('country', properties.name);
          break;
      }
    } catch (error) {
      console.error('Error fetching address details:', error);
    }

    setShowAutocomplete(false);
    setActiveField('');
  };


  const [newBoundaries, setNewBoundaries] = useState<BoundaryOption[]>([]);

  useEffect(() => {
    if (selectedDocRef) {
      // Find the matching options for dropdowns
      const projectOption = docProjects.find(p => p.value === selectedDocRef.project_id);
      const typeOption = docTypes.find(t => t.value === selectedDocRef.type_id);
      const preparedByOption = organizations.find(o => o.value === selectedDocRef.prepared_by_id);
      const preparedForOption = organizations.find(o => o.value === selectedDocRef.prepared_for_id);
      const statusOption = docRefStatuses.find(s => s.value === selectedDocRef.status);
      const languageOption = languages.find(l => l.value === selectedDocRef.language_id);
      const boundaryOption = boundaries.find(b => b.value === selectedDocRef.boundary_id);

      // Set form values
      if (projectOption) {
        setSelectedProject(projectOption);
      }
      if (typeOption) {
        setSelectedDocType(typeOption);
      }
      if (preparedByOption) {
        setSelectedPreparedBy(preparedByOption);
      }
      if (preparedForOption) {
        setSelectedPreparedFor(preparedForOption);
      }
      if (statusOption) {
        setSelectedStatus(statusOption);
      }
      if (languageOption) {
        setSelectedLanguage(languageOption);
      }
      if (boundaryOption) {
        setSelectedBoundary(boundaryOption);
      }

      // Set date if it exists
      if (selectedDocRef.date_issued) {
        setValue('date_issued', selectedDocRef.date_issued);
      }

      setValue('project_id', selectedDocRef.project_id);
      setValue('link_doc', selectedDocRef.link_doc);
      setValue('title', selectedDocRef.title);
      setValue('type_id', selectedDocRef.type_id);
      setValue('prepared_by_id', selectedDocRef.prepared_by_id);
      setValue('prepared_for_id', selectedDocRef.prepared_for_id);
      setValue('date_issued', selectedDocRef.date_issued);
      setValue('status', selectedDocRef.status);
      setValue('url', selectedDocRef.url);
      setValue('pdf_link', selectedDocRef.pdf_link);
      setValue('language_id', selectedDocRef.language_id);
      setValue('boundary_id', selectedDocRef.boundary_id);
      setValue('boundary_page_ref', selectedDocRef.boundary_page_ref);
      setValue('description', selectedDocRef.description);
      setValue('tags', selectedDocRef.tags);
      setValue('municipality', selectedDocRef.municipality);
      setValue('region', selectedDocRef.region);
      setValue('state', selectedDocRef.state);
      setValue('country', selectedDocRef.country);
    }
  }, [selectedDocRef, setValue]);

  const handleOptionChange = (option: DropdownOption | null, fieldName?: string) => {
    if (option && fieldName) {
      setValue(fieldName as keyof ReferenceDocumentFormFields, option.value);
    }
  };

  const handleProjectChange = (option: DropdownOption | null, event?: React.MouseEvent) => {
    if (option) {
      setValue('project_id', parseInt(option.value.toString()));
      setSelectedProject(option);
    } else {
      setValue('project_id', 0);
      setSelectedProject(undefined);
    }
  };

  const handleDocTypeChange = (option: DropdownOption | null, event?: React.MouseEvent) => {
    if (option) {
      setValue('type_id', parseInt(option.value.toString()));
      setSelectedDocType(option);
    } else {
      setValue('type_id', 0);
      setSelectedDocType(undefined);
    }
  };

  const handlePreparedByChange = (option: DropdownOption | null, event?: React.MouseEvent) => {
    if (option) {
      setValue('prepared_by_id', parseInt(option.value.toString()));
      setSelectedPreparedBy(option);
    } else {
      setValue('prepared_by_id', 0);
      setSelectedPreparedBy(undefined);
    }
  };

  const handlePreparedForChange = (option: DropdownOption | null, event?: React.MouseEvent) => {
    if (option) {
      setValue('prepared_for_id', parseInt(option.value.toString()));
      setSelectedPreparedFor(option);
    } else {
      setValue('prepared_for_id', 0);
      setSelectedPreparedFor(undefined);
    }
  };

  const formatDateTime = (date: Date): string => {
    // Add timezone offset to keep the date consistent
    const userTimezoneOffset = date.getTimezoneOffset() * 60000;
    const localDate = new Date(date.getTime() + userTimezoneOffset);

    // Set time to noon (12:00:00)
    localDate.setHours(12, 0, 0, 0);

    const pad = (num: number) => num.toString().padStart(2, '0');

    const year = localDate.getFullYear();
    const month = pad(localDate.getMonth() + 1);
    const day = pad(localDate.getDate());
    const hours = '12';
    const minutes = '00';
    const seconds = '00';

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  const onDateChange = (dates: Array<Date>) => {
    if (dates && dates[0]) {
      const formattedDate = formatDateTime(dates[0]);
      setValue('date_issued', formattedDate);
    }
  };

  const handleStatusChange = (option: DropdownOption | null) => {
    if (option) {
      setValue('status', String(option.value));
      setSelectedStatus(option);
    }
  };

  const handleLanguageChange = (option: DropdownOption | null) => {
    if (option) {
      setValue('language_id', parseInt(option.value.toString()));
      setSelectedLanguage(option);
    }
  };

  const handleLinkDocChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    // Type guard to ensure we're working with an HTMLInputElement
    if (event.target instanceof HTMLInputElement) {
      const checked = event.target.checked;
      setIsLinkDoc(checked);
      setValue('link_doc', checked as false); // Type assertion since we know it's a boolean
    }
  };

  const onSubmit = async (values: ReferenceDocumentFormFields) => {
    try {
      const docRefData = {
        project_id: values.project_id ? parseInt(values.project_id.toString()) : null,
        link_doc: values.link_doc,
        title: values.title,
        type_id: values.type_id ? parseInt(values.type_id.toString()) : null,
        prepared_by_id: values.prepared_by_id ? parseInt(values.prepared_by_id.toString()) : null,
        prepared_for_id: values.prepared_for_id ? parseInt(values.prepared_for_id.toString()) : null,
        date_issued: values.date_issued,
        status: values.status,
        pdf_link: values.pdf_link || null,
        url: values.url || null,
        language_id: values.language_id ? parseInt(values.language_id.toString()) : null,
        boundary_id: values.boundary_id ? parseInt(values.boundary_id.toString()) : null,
        boundary_page_ref: values.boundary_page_ref ? parseInt(values.boundary_page_ref.toString()) : null,
        description: values.description || null,
        tags: values.tags || null,
        municipality: values.municipality || null,
        region: values.region || null,
        state: values.state || null,
        country: values.country || null
      };

      if (selectedDocRef.id) {
        // Update existing project
        await updateDocRef(docRefData, selectedDocRef.id);
      } else {
        // Create new project
        await createDocRef(docRefData);
      }

      // Call onSubmitSuccess if provided
      if (onSubmitSuccess) {
        onSubmitSuccess();
      }
    } catch (error) {
      console.error('Error saving doc ref:', error);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.refDocForm}>
      <div className={styles.sideBySide}>
        <div className={cn(styles.section, styles.refDocSection)}>
          <label>ADD A NEW DOC</label>
          <hr className={styles.solid}></hr>

          <Field
            labelName={'Project Name'}
            fieldName={'projectName'}
            fieldType={'selectDropDown'}
            options={docProjects}
            handleOptionChange={handleProjectChange}
            isSearchable={true}
            selectedOption={selectedProject}
          />

          <div className={styles.fieldGroup}>
            <Field
              labelName={'Link Doc'}
              fieldName={'link_doc'}
              fieldType={'checkBox'}
              onFieldChange={handleLinkDocChange}
              isChecked={isLinkDoc}
            />
            <Field
              labelName={'Choose Previous Doc Edition from District Database'}
              fieldName={'previousDocEdition'}
              fieldType={'selectDropDown'}
              options={[]}
              handleOptionChange={handleOptionChange}
              isSearchable={true}
            />
          </div>

          <Field
            labelName={'Doc Title'}
            fieldName={'title'}
            fieldType={'textBox'}
            register={register}
            fieldClassName={styles.textField}
          />

          <Field
            labelName={'Doc Type'}
            fieldName={'docType'}
            fieldType={'selectDropDown'}
            options={docTypes}
            handleOptionChange={handleDocTypeChange}
            customClassName={styles.halfLength}
            isSearchable={true}
            selectedOption={selectedDocType}
          />

          <div className={styles.fieldGroup}>
            <Field
              labelName={'Prepared By'}
              fieldName={'preparedBy'}
              fieldType={'selectDropDown'}
              options={organizations}
              handleOptionChange={handlePreparedByChange}
              fieldClassName={styles.leftField}
              isSearchable={true}
              selectedOption={selectedPreparedBy}
            />
            <Field
              labelName={'Prepared For'}
              fieldName={'preparedFor'}
              fieldType={'selectDropDown'}
              options={organizations}
              handleOptionChange={handlePreparedForChange}
              isSearchable={true}
              selectedOption={selectedPreparedFor}
            />
          </div>

          <div className={styles.fieldGroup}>
            <Field
              labelName={'Date Issued'}
              fieldName={'dateIssued'}
              fieldType={'dateTime'}
              onDateChange={onDateChange}
              fieldClassName={styles.leftField}
              value={selectedDocRef?.date_issued ? new Date(selectedDocRef.date_issued) : undefined}
            />
            <Field
              labelName={'Doc Status'}
              fieldName={'docStatus'}
              fieldType={'selectDropDown'}
              options={docRefStatuses}
              handleOptionChange={handleStatusChange}
              isSearchable={true}
              selectedOption={selectedStatus}
            />
          </div>

          <Field
            labelName={'Doc URL (Link to PDF)'}
            fieldName={'pdf_link'}
            fieldType={'textBox'}
            register={register}
            fieldClassName={styles.textField}
          />

          <Field
            labelName={'Website URL'}
            fieldName={'url'}
            fieldType={'textBox'}
            register={register}
            fieldClassName={styles.textField}
          />

          <Field
            labelName={'Language'}
            fieldName={'language'}
            fieldType={'selectDropDown'}
            options={languages}
            handleOptionChange={handleLanguageChange}
            customClassName={styles.halfLength}
            isSearchable={true}
            selectedOption={selectedLanguage}
          />

          <Field
            labelName={'Boundary Page Reference'}
            fieldName={'boundary_page_ref'}
            fieldType={'textBox'}
            register={register}
            fieldClassName={styles.halfLength}
          />

          <Field
            labelName={'Doc Description'}
            fieldName={'description'}
            fieldType={'textArea'}
            register={register}
            fieldClassName={styles.textField}
          />

          <Field
            labelName={'Doc Tags'}
            fieldName={'tags'}
            fieldType={'textArea'}
            register={register}
            fieldClassName={styles.textField}
          />

          <label>LOCATION</label>
          <hr className={styles.solid}></hr>

          <div className={styles.fieldGroup}>
            <div className={styles.autocompleteContainer}>
              <Field
                labelName={'Municipality / County'}
                fieldName={'municipality'}
                fieldType={'textBox'}
                register={register}
                fieldClassName={styles.leftField}
                onFieldChange={handleLocationFieldChange}
                onKeyDown={handleKeyDown}
              />
              {showAutocomplete && activeField === 'municipality' && searchResults.length > 0 && (
                <div className={styles.autocompleteDropdown}>
                  {searchResults.map((result, index) => (
                    <div
                      key={index}
                      className={styles.autocompleteItem}
                      onClick={() => handleAutocompleteSelect(result)}
                    >
                      {result.properties.name}
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div className={styles.autocompleteContainer}>
              <Field
                labelName={'Region / District'}
                fieldName={'region'}
                fieldType={'textBox'}
                register={register}
                onFieldChange={handleLocationFieldChange}
                onKeyDown={handleKeyDown}
              />
              {showAutocomplete && activeField === 'region' && searchResults.length > 0 && (
                <div className={styles.autocompleteDropdown}>
                  {searchResults.map((result, index) => (
                    <div
                      key={index}
                      className={styles.autocompleteItem}
                      onClick={() => handleAutocompleteSelect(result)}
                    >
                      {result.properties.name}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>

          <div className={styles.fieldGroup}>
            <div className={styles.autocompleteContainer}>
              <Field
                labelName={'Province / State'}
                fieldName={'state'}
                fieldType={'textBox'}
                register={register}
                fieldClassName={styles.leftField}
                onFieldChange={handleLocationFieldChange}
                onKeyDown={handleKeyDown}
              />
              {showAutocomplete && activeField === 'state' && searchResults.length > 0 && (
                <div className={styles.autocompleteDropdown}>
                  {searchResults.map((result, index) => (
                    <div
                      key={index}
                      className={styles.autocompleteItem}
                      onClick={() => handleAutocompleteSelect(result)}
                    >
                      {result.properties.name}
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div className={styles.autocompleteContainer}>
              <Field
                labelName={'Country'}
                fieldName={'country'}
                fieldType={'textBox'}
                register={register}
                onFieldChange={handleLocationFieldChange}
                onKeyDown={handleKeyDown}
              />
              {showAutocomplete && activeField === 'country' && searchResults.length > 0 && (
                <div className={styles.autocompleteDropdown}>
                  {searchResults.map((result, index) => (
                    <div
                      key={index}
                      className={styles.autocompleteItem}
                      onClick={() => handleAutocompleteSelect(result)}
                    >
                      {result.properties.country}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>

          <div className={styles.buttonGroup}>
            <Button
              className={styles.cancelButton}
              type="button"
              style={ButtonStyle.Secondary}
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button
              className={styles.submitButton}
              type="submit"
              onClick={(e) => {
                e.preventDefault();
                // setIsSubmitting(true);
                handleSubmit(onSubmit)(e);
              }}
            >
              Save
            </Button>
          </div>
        </div>
        <StudyAreaBoundaryComponent
          error={error}
          setError={setError}
          boundaries={studyAreaBoundaryList.map(boundary => ({
            gid: boundary.gid,
            name: boundary.name,
            thumbnail: boundary.thumbnail,
            extent: boundary.extent,
            geom: boundary.geom
          }))}
          selectedBoundary={selectedBoundary}
          setSelectedBoundary={setSelectedBoundary}
          setValue={setValue}
        />
      </div>
    </form>
  );
};

export const ReferenceDocumentForm = withRouter(ReferenceDocumentFormComponent);
