/// JWT Call helper for base Actions
import { jwtData } from "../../config/config";
import axios, { AxiosResponse } from "axios";
import { fullEndpoint } from "actions/lib/urls";

const config = {
  refreshUrl: jwtData.refreshUrl,
  authToken: jwtData.authToken,
  refreshToken: jwtData.refreshToken,
  authTokenExpiry: jwtData.authTokenExpiry,
  refreshTokenExpiry: jwtData.refreshTokenExpiry,
  expiryOffset: jwtData.expiryOffset,
};

interface axiosAuthError {
  status: number;
  data?: { 
    status?: string;
    data?: { 
      message?: string;
    }
  }
}

interface refreshData {
  authToken?: string;
  expiresIn?: number;
}

/// Wrap function - execute wrappedFunction, and if jwt expired, refresh jwt and then try to execute wrappedFunction again
const wrapRefreshAuthTokenOnFail = async (wrappedFunction: () => Promise<any>) => {
  let fullErr;
  try {
    if (hasAuthExpired()) {
      await refreshAuthToken(fullErr);
    }
    const result = await wrappedFunction();

    if (result && result.status === 200 && result.data && result.data.status && result.data.status === "jwt expired") {
      return Promise.reject(result);
    } else {
      return result;
    }
  } catch(err) {
    fullErr = err;
  }
  try {
    await refreshAuthToken(fullErr as axiosAuthError);
    const results = await wrappedFunction();
    return results;
  } catch(err) {
    throw cleanErrorObject(err as axiosAuthError);
  }
};

/// Refresh Auth Token
/// if there is refresh token and the err is jwt expired, refresh auth token
const refreshAuthToken = async (err?: axiosAuthError) => {
  const refreshToken = localStorage.getItem(config.refreshToken);
  if (hasAuthExpired(err) && refreshToken) {
    try {
      const result = await axios.post<any, AxiosResponse<refreshData>>(fullEndpoint(config.refreshUrl), {}, {
        headers: {
          Authorization: `Bearer ${refreshToken}`
        }
      }); 

      if (result && result.data && result.data.authToken) {
        localStorage.setItem(config.authToken, result.data.authToken);

        if (result.data?.expiresIn) {
          localStorage.setItem(config.authTokenExpiry, `${result.data.expiresIn}`);
        }

        return result;      
      } else {
        throw new Error('Auth Token Empty');
      }
    } catch (err) {
      const newErr = err as axiosAuthError;
      if (
        (newErr && newErr.data &&  newErr.data.data && newErr.data.data.message === 'invalid refresh token') ||
        hasRefreshExpired(newErr)
      ) {
        // Invalid refresh token, remove jwt token & authToken
        localStorage.removeItem(config.refreshToken);
        localStorage.removeItem(config.authToken);
        localStorage.removeItem(config.authTokenExpiry);
        localStorage.removeItem(config.refreshTokenExpiry);
      }

      throw newErr;
    }
  } else if (err) {
    throw new Error(`Invalid refresh token`);
  }
};

const hasAuthExpired = (err?: axiosAuthError) => {
  const jwtExpiryTime = localStorage.getItem(config.authTokenExpiry);

  return hasJWTExpired(err, jwtExpiryTime);
};

const hasRefreshExpired = (err?: axiosAuthError) => {
  const jwtExpiryTime = localStorage.getItem(config.refreshTokenExpiry);

  return hasJWTExpired(err, jwtExpiryTime);
};

/// check if jwt has expired
const hasJWTExpired = (err?: axiosAuthError, jwtExpiryTime?: string | null) => {
  if (jwtExpiryTime) {
    try {
      const expiryTime = parseInt(jwtExpiryTime);
      if((Date.now() + config.expiryOffset) >= expiryTime) {
        return true;
      }
    } catch(err) {
      // tried to parse expiry Time failed, just go on.
    }
  }

  return (err && err.status === 401 && err.data && err.data.data && err.data.data.message === "jwt expired") ||
    (err && err.status===200 && err.data && err.data.status && err.data.status === "jwt expired");
  
};

/// Clean a normal Error object from axios
const cleanErrorObject = (error : { data?: any, status: number, statusText?: string }) => {
  const errorObject = {
    data: error.data,
    status: error.status,
    statusText: error.statusText,
    message: getErrorMessage(error),
    original: error
  };

  return errorObject;
};

/// internal Helpers

/// Get the error message from an axios call
const getErrorMessage = (error: { data?: { msg?: string, message?: string }, statusText?: string }) => {
  if (error.data && error.data.msg) {
    return error.data.msg;
  } else if (error.data && error.data.message) {
    return error.data.message;
  } else {
    return error.statusText;
  }
};

const getAuthorizationHeader = () => {
  const authToken = localStorage.getItem(config.authToken);
  return {
    'Authorization': `JWT ${authToken}`
  };
};

export {
  wrapRefreshAuthTokenOnFail,
  refreshAuthToken,
  hasJWTExpired,
  cleanErrorObject,
  getAuthorizationHeader
};
