import React from "react";
import styles from "./dataTable.module.scss";
import { ColumnConfig } from "components/shared/tableWrapper/tableWrapper";

interface DataTableProps {
  tableData: any[];
  columns: ColumnConfig[];
  onRowClick?: (data: any) => void;
}

export const DataTable: React.FC<DataTableProps> = ({ tableData, columns, onRowClick }) => {
  return (
    <table className={styles.dataTable}>
      <thead>
        <tr>
          {columns.map((column) => (
            <th
              key={column.key}
              style={{ width: column.width ? `${column.width}px` : '200px' }}
              className={styles[column.align || 'left']}
            >
              {column.header}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {tableData.map((row, rowIndex) => (
          <tr key={rowIndex} onClick={() => onRowClick && onRowClick(row)}>
            {columns.map((column) => {
              const cellData = row[column.key];
              const cellContent = column.render ? column.render(row) : typeof cellData === 'object' ? JSON.stringify(cellData) : cellData;
              return (
                <td key={column.key} className={styles[column.align || 'left']} title={cellContent}>
                  {cellContent}
                </td>
              );
            })}
          </tr>
        ))}
      </tbody>
    </table>
  );
};
