import { useEffect } from "react";
import {
  fetchDocProjects,
  fetchDocProjectsTypes,
  fetchDocRefs,
  fetchDocRefsTypes,
  fetchOrgAuthors,
  fetchOrgAuthorsTypes,
  fetchStudyAreaBoundaries,
  fetchLanguages
} from "actions/adminActions";
import { useAdminSetState } from 'context/admin';
import { DocProject, DocRef, StudyAreaBoundary } from "actions/lib/types";

const AdminDataLoader = () => {
  const setAdminState = useAdminSetState();

  const fetchStudyData = async (): Promise<void> => {
    setAdminState((prev) => ({ ...prev, isLoading: true, error: null }));

    try {
      const [
        docProjects,
        docProjectTypes,
        docRefs,
        docRefTypes,
        orgAuthors,
        orgAuthorTypes,
        studyAreaBoundaries,
        languages
      ] = await Promise.all([
        fetchDocProjects(),
        fetchDocProjectsTypes(),
        fetchDocRefs(),
        fetchDocRefsTypes(),
        fetchOrgAuthors(),
        fetchOrgAuthorsTypes(),
        fetchStudyAreaBoundaries(),
        fetchLanguages()
      ]);

      setAdminState(prev => ({
        ...prev,
        docProjectList: docProjects,
        docProjectTypeList: docProjectTypes,
        docRefList: docRefs,
        docRefTypeList: docRefTypes,
        orgAuthorList: orgAuthors,
        orgAuthorTypeList: orgAuthorTypes,
        studyAreaBoundaryList: studyAreaBoundaries,
        languageList: languages,
        isLoading: false
      }));
    } catch (err) {
      setAdminState(prev => ({
        ...prev,
        error: 'Failed to fetch study data',
        isLoading: false
      }));
      console.error('Error fetching study data:', err);
    }
  };

  const updateDocProjectsState = (docProjects: DocProject[]) => {
    setAdminState(prev => ({
      ...prev,
      docProjectList: docProjects
    }));
  };

  const updateDocRefsState = (docRefs: DocRef[]) => {
    setAdminState(prev => ({
      ...prev,
      docRefList: docRefs
    }));
  };

  const updateStudyAreaBoundaryState = (studyAreaBoundaries: StudyAreaBoundary[]) => {
    setAdminState(prev => ({
      ...prev,
      studyAreaBoundaryList: studyAreaBoundaries
    }));
  };

  useEffect(() => {
    fetchStudyData();
  }, []);

  return { fetchStudyData, updateDocProjectsState, updateDocRefsState, updateStudyAreaBoundaryState };
};

export default AdminDataLoader;