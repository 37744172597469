import { FC, ReactNode, ChangeEvent, KeyboardEvent } from "react";
import cn from "clsx";
import styles from "./textField.module.scss";
import { FieldErrors, UseFormRegister, FieldValues } from "react-hook-form";

interface ValidationSchema {
  required?: string | boolean;
  pattern?: {
    value: RegExp;
    message: string;
  };
  minLength?: {
    value: number;
    message: string;
  };
  maxLength?: {
    value: number;
    message: string;
  };
  validate?: (val: string) => string | undefined;
}

interface TextFieldProps {
  name: string;
  type: "email" | "password" | "text" | "textArea";
  register: UseFormRegister<any>;
  errors?: FieldErrors<any>;
  validationSchema?: ValidationSchema;
  label?: string;
  required?: boolean;
  placeholder?: string;
  className?: string;
  labelClassName?: string;
  fieldClassName?: string;
  fieldIcon?: ReactNode;
  onFieldChange?: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onKeyDown?: (event: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

export const TextField: FC<TextFieldProps> = ({
  name,
  label,
  required,
  type,
  placeholder,
  className,
  labelClassName,
  fieldClassName,
  register,
  errors,
  validationSchema,
  fieldIcon,
  onFieldChange,
  onKeyDown,
}) => {
  return (
    <div className={cn(styles.textField, className)}>
      {(label || required) && (
        <label htmlFor={name} className={cn(styles.label, labelClassName)}>
          {label}
          {required && <span className={styles.required}>&emsp;</span>}
        </label>
      )}
      <div className={cn(styles.inputFieldContainer, fieldClassName)}>
        {type === "textArea" ? (
          <textarea
            {...register(name as `${string}`, validationSchema)}
            placeholder={placeholder}
            className={cn(styles.field, fieldClassName)}
            onChange={onFieldChange}
            onKeyDown={onKeyDown}
          />
        ) : (
          <input
            {...register(name as `${string}`, validationSchema)}
            type={type}
            placeholder={placeholder}
            className={cn(styles.field, fieldClassName)}
            onChange={onFieldChange}
            onKeyDown={onKeyDown}
          />
        )}
        {fieldIcon}
      </div>
      {errors && (
        <div className={styles.alertContainer}>
          <span className={styles.alert}>{errors[name]?.message}</span>
        </div>
      )}
    </div>
  );
};

export default TextField;
