import React, { ReactNode, useEffect, useState } from "react";
import cn from "clsx";
import { ModalWrapper } from "components/ui/modalWrapper/modalWrapper";
import Title from "components/ui/typograhpy/title/title";
import TextField from "components/ui/textField/textField";
import { useForm } from "react-hook-form";
import { Button, ButtonStyle } from "components/ui/button/button";
import styles from "./dataModal.module.scss";
import { ReactComponent as XIcon } from "assets/images/icons/icon-x.svg";
import SubHeading from "components/ui/typograhpy/subheading/subheading";
import { TableType, TableWrapper, ColumnConfig } from "../tableWrapper/tableWrapper";
import { UserGroup } from "actions/lib/types";
import BodyText from "components/ui/typograhpy/bodyText/bodyText";
import { ReactComponent as SearchIcon } from "assets/images/icons/icon-search.svg";

interface DataModalProps {
  tableTitle: string;
  isOpen: boolean;
  fieldName: string;
  fieldLabel: string;
  affirmationButtonLabel: string;
  tableLabel: string;
  tableData: UserGroup | any;
  handleCloseTable: (value: boolean) => void;
  listBlurb?: string;
  tableType: TableType;
  columns: ColumnConfig[];
}

export interface DataModalFormFields {
  tableField: string;
}

export const DataModal = ({
  tableTitle,
  isOpen,
  fieldName,
  fieldLabel,
  affirmationButtonLabel,
  tableLabel,
  tableData,
  handleCloseTable,
  listBlurb,
  tableType,
  columns
}: DataModalProps) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<DataModalFormFields>();

  return (
    <ModalWrapper isModalOpen={isOpen}>
      <div className={styles.modalHeader}>
        <div className={styles.titleContainer}>
          <Title>{tableTitle}</Title>
          <Button
            style={ButtonStyle.None}
            onClick={() => {
              handleCloseTable(false);
            }}
          >
            <XIcon />
          </Button>
        </div>
        <div className={styles.fieldHeader}>
          <TextField
            name={fieldName}
            label={fieldLabel}
            type={"text"}
            register={register}
          ></TextField>
          <div className={styles.buttonRow}>
            <Button>{affirmationButtonLabel}</Button>
            <Button
              onClick={() => {
                handleCloseTable(false);
              }}
            >
              {"Cancel"}
            </Button>
          </div>
        </div>
        <div className={styles.listSearchHeader}>
          <SubHeading className={styles.tableLabel}>{tableLabel}</SubHeading>
          {tableType === TableType.List && (
            <TextField
              name={"Search"}
              placeholder="Search"
              type={"text"}
              register={register}
              fieldIcon={<SearchIcon />}
            />
          )}
        </div>
        {listBlurb && <BodyText>{listBlurb}</BodyText>}
      </div>
      <TableWrapper 
        tableType={tableType} 
        tableData={tableData} 
        columns={columns} 
      />
    </ModalWrapper>
  );
};
