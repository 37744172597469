export interface User {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  iconHex: string;
  addedToOrganization?: boolean;
}

export interface UserGroup {
  groupName: string;
  owner: User;
  members: Array<User>;
}

export interface MapLayer {
  layerName: string;
  boxColor: string;
  boxBorderColor: string;
}

export interface MapLayerGroup {
  groupName: string;
  layers: Array<MapLayer>
}

export interface LabelValuePair {
  label: string;
  value: string;
}

export interface Project {
  id: number;
  type: number;
  title: string;
  url: string;
}

export interface DocProject {
  id: number;
  type_id: number;
  title: string;
  url?: string;
  tags?: string;
  lead_consultant_id?: number;
  prepared_for_id?: number;
  status: string;
  notes?: string;
}

export interface DocProjectType {
  id: number;
  name: string;
}

export interface DocRef {
  id: number;
  type_id?: number;
  project_id?: number;
  link_doc: false;
  title?: string;
  prepared_by_id?: number;
  prepared_for_id?: number;
  date_issued?: string;
  status?: string;
  url?: string;
  pdf_link?: string;
  language_id?: number;
  boundary_id?: number;
  boundary_page_ref?: number;
  description?: string;
  tags?: string;
  municipality?: string;
  region?: string;
  state?: string;
  country?: string;
}

export interface DocRefType {
  id: number;
  name: string;
}

export interface Organization {
  id: number;
  type: number;
  name: string;
}

export interface OrgAuthor {
  id: number;
  type_id: number;
  name: string;
  former_name?: string;
  unit_number?: string;
  street?: string;
  municipality: string;
  region?: string;
  state?: string;
  country?: string;
  postal_code?: string;
  phone: string;
  email: string;
  website?: string;
}

export interface OrgAuthorType {
  id: number;
  name: string;
}

export interface StudyAreaBoundary {
  gid: number;
  bndy_id?: number;
  name: string;
  geom: string;
  thumbnail?: string;
  extent?: number[];
  check?: string;
  proj_id?: string;
}

export interface Language {
  id: number;
  name: string;
}

export interface Location {
  lon: string;
  lat: string;
}

export enum DocProjectStatus {
  CLOSED = 'Closed',
  COMPLETED = 'Completed',
  CONSTRUCTION = 'Construction',
  CONSTRUCTION_DESIGN = 'Construction/Design',
  DEFERRED = 'Deferred',
  IN_PROGRESS = 'In Progress',
  PLANNING = 'Planning',
  ON_GOING = 'On-going',
  OPEN = 'Open'
}

export enum DocRefStatus {
  APPEALED = 'Appealed',
  ARCHIVED = 'Archived',
  CURRENT = 'Current',
  DRAFT = 'Draft',
  ISSUED = 'Issued',
  OFFICIAL = 'Official'
}

export interface GeoJSON {
  type: string;
  [key: string]: any;
}