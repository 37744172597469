import { useEffect, useState } from 'react';
import { useHistory } from "react-router";
import Layout from "components/layout/Layout";
import styles from "./study.module.scss";
import { Header } from "components/shared/header/header";
import { ReferenceDocumentForm } from "components/referenceDocumentForm/referenceDocumentForm";
import { StudyProjectForm } from "components/studyProjectForm/studyProjectForm";
import { Tab } from "components/tab/tab";
import UseStudyHook from "./studyHook";
import { AdminContextProvider, useAdminState } from 'context/admin';
import UseAdminHook from "../../adminHook";
import { Button, ButtonStyle } from 'components/ui/button/button';
import { ColumnConfig, TableType, TableWrapper } from 'components/shared/tableWrapper/tableWrapper';
import SmallText from 'components/ui/typograhpy/smallText/smallText';
import { ReactComponent as DeleteIcon } from 'assets/images/icons/icon-trash.svg';
import { DocProject, DocProjectStatus, DocRef, DocRefStatus } from 'actions/lib/types';
import { deleteDocProject, deleteDocRef, fetchDocProjects, fetchDocRefs } from 'actions/adminActions';
import { ConfirmationDialog } from "components/shared/confirmationDialog/confirmationDialog";


const StudyContent = () => {
  // Initialize admin hook to fetch data
  const { updateDocProjectsState,  updateDocRefsState} = UseAdminHook();

  const history = useHistory();
  const [selectedProject, setSelectedProject] = useState<any>(null);
  const [selectedDocRef, setSelectedDocRef] = useState<DocRef | null>(null);

  const { pageTabs } = UseStudyHook();
  const [selectedNavTab, setSelectedNavTab] = useState(pageTabs.docs);

  const adminState = useAdminState();
  const {
    docProjectList,
    orgAuthorList,
    docProjectStatusList,
    docProjectTypeList,
    docRefList,
    docRefTypeList,
    docRefStatusList
  } = adminState;

  const docProjectStatusOptions = Object.values(DocProjectStatus).map(status => ({
    id: status,
    name: status
  }));

  const docRefStatusOptions = Object.values(DocRefStatus).map(status => ({
    id: status,
    name: status
  }));

  // Map projects with resolved references
  const projects = docProjectList.map((project: DocProject) => {
    const leadConsultant = orgAuthorList.find(org => org.id === project.lead_consultant_id);
    const preparedFor = orgAuthorList.find(org => org.id === project.prepared_for_id);
    const projectType = docProjectTypeList.find(type => type.id === project.type_id);
    const status = docProjectStatusOptions.find(s => s.id === project.status)?.name || project.status;

    return {
      ...project,
      type_name: projectType?.name || '',
      lead_consultant_name: leadConsultant?.name || '',
      prepared_for_name: preparedFor?.name || '',
      status: status
    };
  })
    .sort((a, b) => a.title.localeCompare(b.title));

  const docRefs = docRefList.map((docRef: DocRef) => {
    const preparedBy = orgAuthorList.find(org => org.id === docRef.prepared_by_id);
    const preparedFor = orgAuthorList.find(org => org.id === docRef.prepared_for_id);
    const docRefType = docRefTypeList.find(type => type.id === docRef.type_id);
    const status = docRefStatusOptions.find(s => s.id === docRef.status)?.name || docRef.status;

    return {
      ...docRef,
      type_name: docRefType?.name || '',
      project_name: docRef.project_id ? projects.find(p => p.id === docRef.project_id)?.title || '' : '',
      prepared_by_name: preparedBy?.name || '',
      prepared_for_name: preparedFor?.name || '',
      status: status
    };
  })
    .sort((a, b) => (a.title || '').localeCompare(b.title || ''));

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [projectToDelete, setProjectToDelete] = useState<number | null>(null);
  const [docRefToDelete, setDocRefToDelete] = useState<number | null>(null);

  const handleDeleteProject = (e: React.MouseEvent, projectId: number) => {
    e.stopPropagation(); // Prevent row click event
    setProjectToDelete(projectId);
    setIsDeleteDialogOpen(true);
  };

  const handleDeleteDocRef = (e: React.MouseEvent, docRefId: number) => {
    e.stopPropagation(); // Prevent row click event
    setDocRefToDelete(docRefId);
    setIsDeleteDialogOpen(true);
  };

  const handleConfirmDelete = async () => {
    if (projectToDelete) {
      try {
        await deleteDocProject(projectToDelete);

        // Fetch updated projects and update state
        const updatedProjects = await fetchDocProjects();
        updateDocProjectsState(updatedProjects);

        // Close the confirmation dialog
        setIsDeleteDialogOpen(false);
        setProjectToDelete(null);
      } catch (error) {
        console.error('Error deleting project:', error);
        // TODO: Show error message to user
      }
    }
    if (docRefToDelete) {
      try {
        await deleteDocRef(docRefToDelete);
        
        // Fetch updated doc refs and update state
        const updatedDocRefs = await fetchDocRefs();
        updateDocRefsState(updatedDocRefs);

        setIsDeleteDialogOpen(false);
        setDocRefToDelete(null);
      } catch (error) {
        console.error('Error deleting document:', error);
        // TODO: Show error message to user
      }
    }
  };

  const projectColumns: ColumnConfig[] = [
    {
      key: 'type_name',
      header: <SmallText>Type</SmallText>,
    },
    {
      key: 'title',
      header: <SmallText>Title</SmallText>,
      align: 'left' as const,
      width: 200,
    },
    {
      key: 'lead_consultant_name',
      header: <SmallText>Lead Consultant</SmallText>,
    },
    {
      key: 'prepared_for_name',
      header: <SmallText>Prepared For</SmallText>,
    },
    {
      key: 'status',
      header: <SmallText>Status</SmallText>,
    },
    {
      key: 'actions',
      header: <SmallText> </SmallText>,
      width: 30,
      render: (project: any) => (
        <div className={styles.actionColumn}>
          <button
            className={styles.deleteButton}
            onClick={(e) => handleDeleteProject(e, project.id)}
            title="Delete Project"
          >
            <DeleteIcon className={styles.deleteIcon} />
          </button>
        </div>
      ),
    }
  ];

  const docRefColumns: ColumnConfig[] = [
    {
      key: 'type_name',
      header: <SmallText>Type</SmallText>,
      width: 100,
    },
    {
      key: 'title',
      header: <SmallText>Title</SmallText>,
      align: 'left' as const,
    },
    {
      key: 'project_name',
      header: <SmallText>Project Name</SmallText>,
    },
    {
      key: 'prepared_by_name',
      header: <SmallText>Prepared By</SmallText>,
    },
    {
      key: 'prepared_for_name',
      header: <SmallText>Prepared For</SmallText>,
    },
    {
      key: 'status',
      header: <SmallText>Status</SmallText>,
      width: 100,
    },
    {
      key: 'actions',
      header: <SmallText> </SmallText>,
      width: 30,
      render: (docRef: any) => (
        <div className={styles.actionColumn}>
          <button
            className={styles.deleteButton}
            onClick={(e) => handleDeleteDocRef(e, docRef.id)}
            title="Delete Document"
          >
            <DeleteIcon className={styles.deleteIcon} />
          </button>
        </div>
      ),
    }
  ];

  const handleBackClick = () => {
    if (selectedProject) {
      setSelectedProject(null); // Return to project list
    } else if (selectedDocRef) {
      setSelectedDocRef(null); // Return to doc ref list
    } else {
      history.goBack(); // Navigate back to previous page
    }
  };

  const handleProjectClick = (project: any) => {
    setSelectedProject(project);

    console.log('Selected project:', project);
  };

  const handleDocRefClick = (docRef: DocRef) => {
    setSelectedDocRef(docRef);
    console.log('Selected docRef:', docRef);
  };

  const handleAddProject = () => {
    // setIsCreating(true);
    setSelectedProject({});
  };

  const handleAddDocRef = () => {
    setSelectedDocRef({} as DocRef);
  };

  const handleProjectSubmitSuccess = async () => {
    try {
      // Fetch updated projects and update state
      const updatedProjects = await fetchDocProjects();
      updateDocProjectsState(updatedProjects);

      // Return to project list
      setSelectedProject(null);
    } catch (error) {
      console.error('Error refreshing projects:', error);
    }
  };

  const handleDocRefSubmitSuccess = async () => {
    try {
      // Fetch updated doc refs and update state
      const updatedDocRefs = await fetchDocRefs();
      updateDocRefsState(updatedDocRefs);

      // Return to doc ref list
      setSelectedDocRef(null);
    } catch (error) {
      console.error('Error refreshing doc refs:', error);
    }
  };

  // Function to handle tab click
  const handleNavTabClick = (tab: string) => {
    setSelectedNavTab(tab);
  };

  return (
    <div>
      <div className={styles.studyHeader}>
        <Header
          headerName={selectedProject || selectedDocRef ? "Return" : "Projects & Documents"}
          showBackButton={true}
          onBackClick={handleBackClick}
        />
      </div>
      <div className={styles.contentWrapper}>
        <div className={styles.navigationContainer}>
          <Tab
            icon={null}
            tabLabel={pageTabs.docs}
            onClick={() => handleNavTabClick(pageTabs.docs)} isSelected={selectedNavTab === pageTabs.docs} />
          <Tab
            icon={null}
            tabLabel={pageTabs.projects}
            onClick={() => handleNavTabClick(pageTabs.projects)} isSelected={selectedNavTab === pageTabs.projects} />
        </div>
        <div className={styles.appContainer}>
          {selectedNavTab === pageTabs.docs &&
            <>
              {!selectedDocRef ? (
                <div>
                  <div>
                    <Button
                      style={ButtonStyle.Primary}
                      onClick={handleAddDocRef}
                    >
                      + Add Document
                    </Button>
                  </div>
                  <div className={styles.tableContainer}>
                    <TableWrapper
                      tableType={TableType.Table}
                      columns={docRefColumns}
                      tableData={docRefs}
                      onRowClick={handleDocRefClick}
                    />
                  </div>
                </div>
              ) : (
                <ReferenceDocumentForm
                  selectedDocRef={selectedDocRef}
                  onCancel={() => setSelectedDocRef(null)}
                  onSubmitSuccess={handleDocRefSubmitSuccess}
                />
              )}
            </>
          }

          {selectedNavTab === pageTabs.projects &&
            <>
              {!selectedProject ? (
                <div>
                  <div>
                    <Button
                      style={ButtonStyle.Primary}
                      onClick={handleAddProject}
                    >
                      + Add Project
                    </Button>
                  </div>
                  <div className={styles.tableContainer}>
                    <TableWrapper
                      tableType={TableType.Table}
                      columns={projectColumns}
                      tableData={projects}
                      onRowClick={handleProjectClick}
                    />
                  </div>
                </div>
              ) : (
                <StudyProjectForm
                  selectedProject={selectedProject}
                  onCancel={() => setSelectedProject(null)}
                  onSubmitSuccess={handleProjectSubmitSuccess}
                />
              )}
            </>
          }
        </div>
      </div>
      <ConfirmationDialog
        isOpen={isDeleteDialogOpen}
        title={projectToDelete !== null ? "Delete Project" : "Delete Document"}
        message={projectToDelete !== null
          ? "Are you sure you want to delete this project?" 
          : "Are you sure you want to delete this document?"}
        onConfirm={handleConfirmDelete}
        onCancel={() => {
          setIsDeleteDialogOpen(false);
          setProjectToDelete(null);
          setDocRefToDelete(null);
        }}
        confirmText="Delete"
        cancelText="Cancel"
      />
    </div>
  );
};

export const Study = () => {
  return (
    <Layout>
      <AdminContextProvider>
        <StudyContent />
      </AdminContextProvider>
    </Layout>
  );
};
