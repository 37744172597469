import React, { ReactNode, useEffect, useState } from "react";
import styles from "./tableWrapper.module.scss";
import { DataList } from "components/ui/dataList/dataList";
import { DataTable } from "components/ui/dataTable/dataTable";

export enum TableType {
  Table,
  List,
}

export interface ColumnConfig {
  key: string;
  header: ReactNode;
  render?: (rowData: any) => ReactNode;
  align?: 'left' | 'center' | 'right';
  width?: number;
}

interface TableWrapperProps<T = any> {
  tableData: T[];
  columns: ColumnConfig[];
  tableType: TableType;
  onRowClick?: (data: T) => void;
}

const _renderTableType = (
  tableData: any,
  tableType: any,
  columns: ColumnConfig[],
  onRowClick?: (data: any) => void
) => {
  switch (tableType) {
    case TableType.Table:
      return <DataTable
        tableData={tableData}
        columns={columns}
        onRowClick={onRowClick}
      />;
    case TableType.List:
      return <DataList tableData={tableData} />;
  }
};

export const TableWrapper = <T, >({
  tableData,
  tableType,
  columns,
  onRowClick
}: TableWrapperProps<T>) => {
  return (
    <div className={styles.tableContainer}>
      {_renderTableType(tableData, tableType, columns, onRowClick)}
    </div>
  );
};
