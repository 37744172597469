import { useState } from 'react';
import Layout from "components/layout/Layout";
import styles from "./organization.module.scss";
import { Header } from "components/shared/header/header";
import { OrganizationForm } from "components/organizationForm/organizationForm";
import { useHistory } from "react-router";
import { TableType, TableWrapper, ColumnConfig } from "components/shared/tableWrapper/tableWrapper";
import SmallText from "components/ui/typograhpy/smallText/smallText";
import { AdminContextProvider } from 'context/admin';
import UseAdminHook from "../../adminHook";
import { useAdminState } from 'context/admin';
import { OrgAuthor } from 'actions/lib/types';
import { Button, ButtonStyle } from "components/ui/button/button";
import { deleteOrgAuthor } from 'actions/adminActions';
import { ReactComponent as DeleteIcon } from 'assets/images/icons/icon-trash.svg';
import { ConfirmationDialog } from "components/shared/confirmationDialog/confirmationDialog";

const OrganizationContent = () => {
  // Initialize admin hook to fetch data
  UseAdminHook();

  const history = useHistory();
  const [selectedOrg, setSelectedOrg] = useState<any>(null);
  const [isCreating, setIsCreating] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [orgToDelete, setOrgToDelete] = useState<number | null>(null);

  const adminState = useAdminState();
  const {
    orgAuthorList,
  } = adminState;

  const organizations = orgAuthorList
    .map((orgAuthor: OrgAuthor) => {
      // Create formatted address
      const addressParts = [
        orgAuthor.unit_number,
        orgAuthor.street,
        orgAuthor.municipality,
        orgAuthor.region,
        orgAuthor.state,
        orgAuthor.country,
        orgAuthor.postal_code
      ];

      // Filter out null/undefined values and join with commas
      const formattedAddress = addressParts
        .filter(part => part)
        .join(', ');

      return {
        id: orgAuthor.id,
        name: orgAuthor.name,
        former_name: orgAuthor.former_name,
        type_id: orgAuthor.type_id,
        address: formattedAddress,
        phone: orgAuthor.phone,
        email: orgAuthor.email,
        website: orgAuthor.website,
        // Keep original fields for form editing
        unit_number: orgAuthor.unit_number,
        street: orgAuthor.street,
        municipality: orgAuthor.municipality,
        region: orgAuthor.region,
        state: orgAuthor.state,
        country: orgAuthor.country,
        postal_code: orgAuthor.postal_code,
      };
    })
    .sort((a, b) => a.name.localeCompare(b.name));

  const handleAddOrganization = () => {
    setIsCreating(true);
    setSelectedOrg({});
  };

  const handleBackClick = () => {
    if (selectedOrg) {
      setSelectedOrg(null);
      setIsCreating(false);
    } else {
      history.goBack();
    }
  };

  const handleDeleteOrg = (e: React.MouseEvent, orgId: number) => {
    e.stopPropagation(); // Prevent row click event
    setOrgToDelete(orgId);
    setIsDeleteDialogOpen(true);
  };

  const handleConfirmDelete = async () => {
    if (orgToDelete) {
      try {
        await deleteOrgAuthor(orgToDelete);
        window.location.reload();
      } catch (error) {
        console.error('Error deleting organization:', error);
        // Handle error (e.g., show error message)
      }
    }
    setIsDeleteDialogOpen(false);
  };

  const columns: ColumnConfig[] = [
    {
      key: 'name',
      header: <SmallText>Name</SmallText>,
      align: 'left' as const
    },
    {
      key: 'address',
      header: <SmallText>Address</SmallText>,
      width: 400,
    },
    {
      key: 'phone',
      header: <SmallText>Phone</SmallText>,
    },
    {
      key: 'email',
      header: <SmallText>Email</SmallText>,
    },
    {
      key: 'actions',
      header: <SmallText> </SmallText>,
      width: 30,
      render: (org: any) => (
        <div className={styles.actionColumn}>
          <button
            className={styles.deleteButton}
            onClick={(e) => handleDeleteOrg(e, org.id)}
            title="Delete Organization"
          >
            <DeleteIcon className={styles.deleteIcon} />
          </button>
        </div>
      ),
    }
  ];

  const handleOrgClick = (org: any) => {
    setSelectedOrg(org);

    console.log('Selected org:', org);
  };

  return (
    <div>
      <div className={styles.studyHeader}>
        <Header
          headerName={selectedOrg ? (isCreating ? "Create Organization" : "Return") : "Organizations"}
          showBackButton={true}
          onBackClick={handleBackClick}
        />
      </div>
      <div className={styles.contentWrapper}>
        <div className={styles.appContainer}>
          <>
            {!selectedOrg ? (
              <div>
                <div>
                  <Button 
                    style={ButtonStyle.Primary}
                    onClick={handleAddOrganization}
                  >
                    + Add Organization
                  </Button>
                </div>
                <div className={styles.tableContainer}>
                  <TableWrapper
                    tableType={TableType.Table}
                    columns={columns}
                    tableData={organizations}
                    onRowClick={handleOrgClick}
                  />
                </div>
              </div>
            ) : (
              <OrganizationForm
                selectedOrg={selectedOrg}
                isCreating={isCreating}
                onCancel={() => {
                  setSelectedOrg(null);
                  setIsCreating(false);
                }}
              />
            )}
          </>
        </div>
      </div>
      <ConfirmationDialog
        isOpen={isDeleteDialogOpen}
        title="Delete Organization"
        message="Are you sure you want to delete this organization?"
        onConfirm={handleConfirmDelete}
        onCancel={() => setIsDeleteDialogOpen(false)}
        confirmText="Delete"
        cancelText="Cancel"
      />
    </div>
  );
};

export const Organization = () => {
  return (
    <Layout>
      <AdminContextProvider>
        <OrganizationContent />
      </AdminContextProvider>
    </Layout>
  );
};
