import { useState } from "react";

const UseStudyHook: any = () => {
  enum pageTabs {
    docs = 'Docs',
    projects = 'Projects'
  }

  const [selectedTab, setSelectedTab] = useState<string>('');
  const [selectedNavTab, setSelectedNavTab] = useState<string>('');
  const [isFirstTabSelected, setIsFirstTabSelected] = useState<boolean>(true);
  const [isLastTabSelected, setIsLastTabSelected] = useState<boolean>(false);

  const onTabClick = (event: any) => {
    const index = event.currentTarget.id.split('-')[1];
    if (parseInt(index) === 0) {
      setIsFirstTabSelected(true);
      setIsLastTabSelected(false);
    } else {
      setIsFirstTabSelected(false);
      setIsLastTabSelected(false);
    }
  };

  const onNavTabClick = (event: any) => {
    const id = event.currentTarget.id.split('-')[1];
    setSelectedNavTab(id);
  };

  return {
    selectedTab,
    setSelectedTab,
    selectedNavTab,
    setSelectedNavTab,
    isFirstTabSelected,
    setIsFirstTabSelected,
    isLastTabSelected,
    setIsLastTabSelected,
    onTabClick,
    onNavTabClick,
    pageTabs
  };
};

export default UseStudyHook;