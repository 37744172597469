
export const baseAPIUrl = process.env.REACT_APP_API_URL;
export const captchaSiteKey = process.env.REACT_APP_CAPTCHA_SITE_KEY;

// Jwt specific data 
export const jwtData = { 
  refreshUrl: 'refreshAuth',
  authToken: 'authToken',
  refreshToken: 'refreshToken',
  authTokenExpiry: 'authTokenExpiry',
  refreshTokenExpiry: 'refreshTokenExpiry',
  expiryOffset: 60*1000,
};

export const apiEndpoints = {
  login: 'login',
  users: 'users',
  usersMe: 'users/me',
  healthCheckApi: 'healthCheck/api',
  healthCheckDatabase: 'healthCheck/database',
  register: 'register',
  doc_projects: 'doc_projects',
  doc_project: 'doc_project',
  doc_project_types: 'doc_project_types',
  org_authors: 'org_authors',
  org_author: 'org_author',
  org_author_types: 'org_author_types',
  doc_refs: 'doc_refs',
  doc_ref: 'doc_ref',
  doc_ref_types: 'doc_ref_types',
  languages: 'languages',
  study_area_boundaries: 'study_area_boundaries',
  study_area_boundary: 'study_area_boundary',
};
