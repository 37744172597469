import Select, { StylesConfig, components } from "react-select";
import cn from "clsx";
import styles from "./selectDropdown.module.scss";
import { DropdownOption } from "../optionDropdown/optionDropdown";

// Extend DropdownOption to include thumbnail
interface OptionWithThumbnail extends DropdownOption {
  thumbnail?: string;
}

// Custom Option component
const Option = (props: any) => {
  return (
    <components.Option {...props}>
      <div className={styles.optionWithThumbnail}>
        {props.data.thumbnail && (
          <img 
            src={props.data.thumbnail} 
            alt={props.data.label}
            className={styles.thumbnail}
          />
        )}
        <span>{props.data.label}</span>
      </div>
    </components.Option>
  );
};

// Custom SingleValue component
const SingleValue = (props: any) => {
  return (
    <components.SingleValue {...props}>
      <div className={styles.optionWithThumbnail}>
        {props.data.thumbnail && (
          <img 
            src={props.data.thumbnail} 
            alt={props.data.label}
            className={styles.thumbnail}
          />
        )}
        <span>{props.data.label}</span>
      </div>
    </components.SingleValue>
  );
};

interface SelectDropdownProps {
  label: string;
  options: Array<DropdownOption>;
  className?: string;
  handleOptionChange: (option: DropdownOption | null) => void;
  selectedOption?: DropdownOption;
  isSearchable?: boolean;
  placeholder?: string;
  components?: {
    Option?: React.ComponentType<any>;
    SingleValue?: React.ComponentType<any>;
  };
}

export const SelectDropdown = ({
  label,
  options,
  className,
  handleOptionChange,
  selectedOption,
  isSearchable = false,
  placeholder = "Select...",
  components
}: SelectDropdownProps) => {
  const customStyles: StylesConfig<OptionWithThumbnail, false> = {
    control: (provided, state) => ({
      ...provided,
      borderRadius: styles.borderRadius,
      boxShadow: 'none',
      borderColor: state.isFocused ? styles.primaryColor : styles.borderColor,
      '&:hover': {
        borderColor: styles.primaryColor
      }
    }),
    option: (provided, state) => ({
      ...provided,
      cursor: 'pointer',
      backgroundColor: state.isSelected ? styles.primaryColor : state.isFocused ? styles.hoverColor : styles.transparentColor,
      color: state.isSelected ? styles.whiteColor : styles.textColor,
      padding: '8px 12px',
      display: 'flex',
      alignItems: 'center'
    }),
    singleValue: (provided) => ({
      ...provided,
      marginLeft: styles.marginLeft,
      color: styles.textColor
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: `${styles.paddingY} ${styles.paddingX}`
    }),
    placeholder: (provided) => ({
      ...provided,
      color: styles.placeholderColor
    })
  };

  return (
    <div className={cn(styles.selectWrapper, className)}>
      {label && <label className={styles.label}>{label}</label>}
      <Select<OptionWithThumbnail, false>
        value={selectedOption}
        onChange={(option: OptionWithThumbnail | null) => handleOptionChange(option)}
        options={options}
        styles={customStyles}
        isSearchable={isSearchable}
        placeholder={placeholder}
        className={styles.select}
        classNamePrefix="react-select"
        components={components || { Option, SingleValue }}
      />
    </div>
  );
}; 