import { ChangeEvent, KeyboardEvent } from 'react';
import { UseFormRegister } from "react-hook-form";
import { OptionDropdown } from "components/ui/optionDropdown/optionDropdown";
import { ReactComponent as ArrowDownIcon } from "assets/images/icons/icon-arrow-down.svg";
import { Checkbox } from "components/ui/checkbox/checkbox";
import { TextField } from "components/ui/textField/textField";
import { DateTime } from "components/ui/dateTime/dateTime";
import cn from "clsx";
import styles from "./field.module.scss";
import { SelectDropdown } from "components/ui/selectDropdown/selectDropdown";

export interface DropdownOption {
  label: string;
  value: string | number;
}

interface FieldProps {
  labelName: string;
  fieldName: string;
  fieldType: string;
  options?: Array<DropdownOption>;
  handleOptionChange?: (option: DropdownOption | null) => void;
  register?: UseFormRegister<any>;
  onDateChange?: (value: Array<Date>) => void;
  onFieldChange?: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  fieldClassName?: string;
  customClassName?: string;
  isChecked?: boolean;
  onKeyDown?: (event: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  selectedOption?: DropdownOption;
  isSearchable?: boolean;
  placeholder?: string;
  value?: string | Date | Date[];
  components?: {
    Option?: React.ComponentType<any>;
    SingleValue?: React.ComponentType<any>;
  };
}

export const Field = ({
  labelName,
  fieldName,
  fieldType,
  options,
  handleOptionChange,
  register,
  onDateChange,
  onFieldChange,
  fieldClassName,
  customClassName,
  isChecked,
  onKeyDown,
  selectedOption,
  isSearchable,
  placeholder,
  value,
  components
}: FieldProps) => {

  let fieldComponent = <div></div>;

  switch (fieldType) {
    case 'textBox':
      fieldComponent = register ? <div className={styles.field} >
        <label>{labelName}</label>
        <TextField 
          name={fieldName} 
          label={''} 
          type={'text'} 
          fieldClassName={cn(fieldClassName)} 
          register={register}
          onFieldChange={onFieldChange}
          onKeyDown={onKeyDown}
        ></TextField>
      </div> : <div></div>;
      break;
    case 'textArea':
      fieldComponent = register ? <div className={styles.field} >
        <label>{labelName}</label>
        <TextField name={fieldName} label={''} type={'textArea'} fieldClassName={cn(fieldClassName)} register={register} onKeyDown={onKeyDown}></TextField>
      </div> : <div></div>;
      break;
    case 'dropDown':
      fieldComponent = (options && handleOptionChange) ? <div className={cn(styles.field, fieldClassName)} >
        <label>{labelName}</label>
        <OptionDropdown label={''} options={options} handleOptionChange={handleOptionChange} className={cn(customClassName)} dropdownIcon={<ArrowDownIcon />} selectedOption={selectedOption} />
      </div> : <div></div>;
      break;
    case 'checkBox':
      fieldComponent = onFieldChange ? <div className={cn(styles.checkboxField, customClassName)}>
        <label>{labelName}</label>
        <Checkbox label={''} onFieldChange={onFieldChange} isChecked={isChecked} />
      </div> : <div></div>;
      break;
    case 'dateTime':
      fieldComponent = <div className={cn(styles.field, fieldClassName)}>
        <label>{labelName}</label>
        <DateTime
          label={''}
          value={value instanceof Date ? [value] : undefined}
          onChange={onDateChange}
          isRange={false}
          groupName={'key'}
          showTimeSelect={false}
          dateFormat={'MMMM d, yyyy'}
        />
      </div>;
      break;
    case 'selectDropDown':
      fieldComponent = (options && handleOptionChange) ? (
        <div className={cn(styles.field, fieldClassName)}>
          <label>{labelName}</label>
          <SelectDropdown
            label={''}
            options={options}
            handleOptionChange={handleOptionChange}
            className={cn(customClassName)}
            selectedOption={selectedOption}
            isSearchable={isSearchable}
            placeholder={placeholder}
            components={components}
          />
        </div>
      ) : <div></div>;
      break;
  }

  return fieldComponent;
};
