import { ReactNode } from 'react';
import { ModalWrapper } from 'components/ui/modalWrapper/modalWrapper';
import styles from './dialog.module.scss';

interface DialogProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title: string;
  children: ReactNode;
  confirmDisabled?: boolean;
}

export const Dialog = ({ 
  isOpen, 
  onClose, 
  onConfirm, 
  title, 
  children, 
  confirmDisabled = false 
}: DialogProps) => {
  return (
    <ModalWrapper isModalOpen={isOpen} className={styles.modalWrapper}>
      <div className={styles.dialogPanel}>
        <div className={styles.header}>
          <h2 className={styles.title}>{title}</h2>
        </div>
        
        <div className={styles.content}>
          {children}
        </div>

        <div className={styles.footer}>
          <button
            className={styles.cancelButton}
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className={styles.confirmButton}
            onClick={onConfirm}
            disabled={confirmDisabled}
          >
            OK
          </button>
        </div>
      </div>
    </ModalWrapper>
  );
}; 