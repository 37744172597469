import * as base from "./lib/baseActions";
import { apiEndpoints } from "../config/config";
import { getAuthorizationHeader } from "./lib/jwtActions";
import { _mockDataForProjects } from "pages/admin/mockData/mockDataForProjects";
import { _mockDataForOrganizations } from "pages/admin/mockData/mockDataForOrganizations";

const GEOCODING_QUERY_RESULT_LIMIT = 10;

const fetchProjects = async () => {
  try {
    // TODO: Replace mock data with API call
    return _mockDataForProjects;
  }
  catch {
    return [];
  }
};

const fetchDocProjects = async () => {
  try {
    const projects = await base.get(apiEndpoints.doc_projects, {
      headers: getAuthorizationHeader()
    });
    return projects.data?.data;
  }
  catch {
    return [];
  }
};

const fetchDocProjectsTypes = async () => {
  try {
    const projectTypes = await base.get(apiEndpoints.doc_project_types, {
      headers: getAuthorizationHeader()
    });
    return projectTypes.data?.data;
  }
  catch {
    return [];
  }
};

const fetchDocRefs = async () => {
  try {
    const docRefs = await base.get(apiEndpoints.doc_refs, {
      headers: getAuthorizationHeader()
    });
    return docRefs.data?.data;
  }
  catch {
    return [];
  }
};

const fetchDocRefsTypes = async () => {
  try {
    const docRefTypes = await base.get(apiEndpoints.doc_ref_types, {
      headers: getAuthorizationHeader()
    });
    return docRefTypes.data?.data;
  }
  catch {
    return [];
  }
};

const fetchOrgAuthors = async () => {
  try {
    const orgAuthors = await base.get(apiEndpoints.org_authors, {
      headers: getAuthorizationHeader()
    });
    return orgAuthors.data?.data;
  }
  catch {
    return [];
  }
};

const fetchOrgAuthorsTypes = async () => {
  try {
    const orgAuthorTypes = await base.get(apiEndpoints.org_author_types, {
      headers: getAuthorizationHeader()
    });
    return orgAuthorTypes.data?.data;
  }
  catch {
    return [];
  }
};

const fetchOrganizations = async () => {
  try {
    // TODO: Replace mock data with API call
    return _mockDataForOrganizations;
  }
  catch {
    return [];
  }
};

const fetchStudyAreaBoundaries = async () => {
  try {
    const studyAreaBoundaries = await base.get(apiEndpoints.study_area_boundaries, {
      headers: getAuthorizationHeader()
    });
    return studyAreaBoundaries.data?.data;
  }
  catch {
    return [];
  }
};

const fetchLanguages = async () => {
  try {
    const languages = await base.get(apiEndpoints.languages, {
      headers: getAuthorizationHeader()
    });
    return languages.data?.data;
  }
  catch {
    return [];
  }
};

const updateOrgAuthor = async (orgAuthorData: any, id: number) => {
  try {
    let endpoint = apiEndpoints.org_author;

    // If we're updating an existing org author (has an ID)
    if (id) {
      endpoint = `${endpoint}/${id}`;
    }

    const response = await base.put(endpoint, orgAuthorData, {
      headers: getAuthorizationHeader()
    });
    return response.data;
  }
  catch (error) {
    console.error('Error updating organization author:', error);
    throw error;
  }
};

const createOrgAuthor = async (orgAuthorData: any) => {
  try {
    const response = await base.post(`${apiEndpoints.org_author}/create`, orgAuthorData, {
      headers: getAuthorizationHeader()
    });
    return response.data;
  }
  catch (error) {
    console.error('Error creating organization author:', error);
    throw error;
  }
};

const deleteOrgAuthor = async (id: number) => {
  try {
    const response = await base.del(`${apiEndpoints.org_author}/${id}`, {
      headers: getAuthorizationHeader()
    });
    return response.data;
  }
  catch (error) {
    console.error('Error deleting organization author:', error);
    throw error;
  }
};

const updateDocProject = async (docProjectData: any, id: number) => {
  try {
    let endpoint = apiEndpoints.doc_project;

    // If we're updating an existing doc project (has an ID)
    if (id) {
      endpoint = `${endpoint}/${id}`;
    }

    const response = await base.put(endpoint, docProjectData, {
      headers: getAuthorizationHeader()
    });
    return response.data;
  }
  catch (error) {
    console.error('Error updating doc project:', error);
    throw error;
  }
};

const createDocProject = async (docProjectData: any) => {
  try {
    const response = await base.post(`${apiEndpoints.doc_project}/create`, docProjectData, {
      headers: getAuthorizationHeader()
    });
    return response.data;
  }
  catch (error) {
    console.error('Error creating doc project:', error);
    throw error;
  }
};

const deleteDocProject = async (id: number) => {
  try {
    const response = await base.del(`${apiEndpoints.doc_project}/${id}`, {
      headers: getAuthorizationHeader()
    });
    return response.data;
  }
  catch (error) {
    console.error('Error deleting doc project:', error);
    throw error;
  }
};

const updateDocRef = async (docRefData: any, id: number) => {
  try {
    const response = await base.put(`${apiEndpoints.doc_ref}/${id}`, docRefData, {
      headers: getAuthorizationHeader()
    });
    return response.data;
  }
  catch (error) {
    console.error('Error updating doc ref:', error);
    throw error;
  }
};

const createDocRef = async (docRefData: any) => {
  try {
    const response = await base.post(`${apiEndpoints.doc_ref}/create`, docRefData, {
      headers: getAuthorizationHeader()
    });
    return response.data;
  }
  catch (error) {
    console.error('Error creating doc ref:', error);
    throw error;
  }
};

const deleteDocRef = async (id: number) => {
  try {
    const response = await base.del(`${apiEndpoints.doc_ref}/${id}`, {
      headers: getAuthorizationHeader()
    });
    return response.data;
  }
  catch (error) {
    console.error('Error deleting doc ref:', error);
    throw error;
  }
};

const createStudyAreaBoundary = async (studyAreaBoundaryData: any) => {
  try {
    const response = await base.post(`${apiEndpoints.study_area_boundary}/create`, studyAreaBoundaryData, {
      headers: getAuthorizationHeader()
    });
    return response.data;
  }
  catch (error) {
    console.error('Error creating study area boundary:', error);
    throw error;
  }
};

const deleteStudyAreaBoundary = async (id: number) => {
  try {
    const response = await base.del(`${apiEndpoints.study_area_boundary}/${id}`, {
      headers: getAuthorizationHeader()
    });
    return response.data;
  }
  catch (error) {
    console.error('Error deleting study area boundary:', error);
    throw error;
  }
};

const updateStudyAreaBoundary = async (studyAreaBoundaryData: any, id: number) => {
  try {
    const response = await base.put(`${apiEndpoints.study_area_boundary}/${id}`, studyAreaBoundaryData, {
      headers: getAuthorizationHeader()
    });
    return response.data;
  }
  catch (error) {
    console.error('Error updating study area boundary:', error);
    throw error;
  }
};

const fetchGeocodingResults = async (searchQuery: string, locationSuffix: string, layer?: string) => {
  try {
    const params: Record<string, string> = {
      q: searchQuery + ', ' + locationSuffix,
      limit: GEOCODING_QUERY_RESULT_LIMIT.toString(),
    };

    // Only add layer parameter if it exists
    if (layer) {
      params.layer = layer;
    }

    const searchParams = new URLSearchParams(params);
    const response = await fetch(`https://photon.komoot.io/api/?${searchParams.toString()}`);
    const data = await response.json();
    return data;
  }
  catch {
    return null;
  }
};

const fetchAddressDetails = async (osmId: string) => {
  try {
    const params = new URLSearchParams({
      osm_ids: osmId,
      format: 'json',
    });

    const response = await fetch(`https://nominatim.openstreetmap.org/lookup?${params.toString()}`, {
      headers: {
        'Accept': 'application/json',
        'User-Agent': 'DistrictMaps/2.0'
      }
    });
    const data = await response.json();
    return data;
  }
  catch {
    return null;
  }
};

export {
  fetchProjects,
  fetchDocProjects,
  fetchDocProjectsTypes,
  fetchDocRefs,
  fetchDocRefsTypes,
  fetchOrgAuthors,
  fetchOrgAuthorsTypes,
  fetchOrganizations,
  fetchStudyAreaBoundaries,
  fetchLanguages,
  fetchGeocodingResults,
  fetchAddressDetails,
  updateOrgAuthor,
  createOrgAuthor,
  deleteOrgAuthor,
  updateDocProject,
  createDocProject,
  deleteDocProject,
  updateDocRef,
  createDocRef,
  deleteDocRef,
  createStudyAreaBoundary,
  deleteStudyAreaBoundary,
  updateStudyAreaBoundary
};