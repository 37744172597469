import { creatContextStore } from './base';
import {
  DocProject,
  DocProjectType,
  DocProjectStatus,
  DocRef, 
  DocRefType, 
  DocRefStatus, 
  OrgAuthor, 
  OrgAuthorType, 
  StudyAreaBoundary, 
  Language
} from 'actions/lib/types';

interface AdminState {
  docProjectList: DocProject[];
  docProjectTypeList: DocProjectType[];
  docProjectStatusList: DocProjectStatus[];
  docRefList: DocRef[];
  docRefTypeList: DocRefType[];
  docRefStatusList: DocRefStatus[];
  orgAuthorList: OrgAuthor[];
  orgAuthorTypeList: OrgAuthorType[];
  studyAreaBoundaryList: StudyAreaBoundary[];
  languageList: Language[];
  isLoading: boolean;
  error: string | null;
}

const initialState: AdminState = {
  docProjectList: [],
  docProjectTypeList: [],
  docProjectStatusList: [],
  docRefList: [],
  docRefTypeList: [],
  docRefStatusList: [],
  orgAuthorList: [],
  orgAuthorTypeList: [],
  studyAreaBoundaryList: [],
  languageList: [],
  isLoading: false,
  error: null
};

const [
  AdminContextProvider,
  useAdminState,
  useAdminSetState,
  useAdminUpdate
] = creatContextStore<AdminState>(initialState);

export {
  AdminContextProvider,
  useAdminState,
  useAdminSetState,
  useAdminUpdate
};